import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import AMSEModulesItemsPurgeEventsButton from './AMSEModulesItemsPurgeEventsButton';
import AMSEModulesItemsPurgeNodeButton from './AMSEModulesItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  amsEModulesIds?: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const AMSEModuleItemsPurgeButton = <T extends ComponentType = typeof Button>({
  amsEModulesIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  const count = useMemo(() => {
    return amsEModulesIds?.length ?? 0;
  }, [amsEModulesIds]);

  const string = useMemo(() => {
    return `${count} e-Module${count > 1 ? 's' : ''}`;
  }, [count]);

  const purgeAllTitle = useMemo(() => {
    return `Device (${string})`;
  }, [count]);

  const purgeTransactionTitle = useMemo(() => {
    return `Transactions (${string})`;
  }, [count]);

  const purgeAllButtonTitle = useMemo(() => {
    return `Purge Device (${string})`;
  }, [count]);

  const purgeTransactionButtonTitle = useMemo(() => {
    return `Purge Transactions (${string})`;
  }, [count]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <AMSEModulesItemsPurgeNodeButton
          amsEModulesIds={amsEModulesIds}
          buttonTitle={purgeAllButtonTitle}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          {purgeAllTitle}
        </AMSEModulesItemsPurgeNodeButton>

        <AMSEModulesItemsPurgeEventsButton
          amsEModulesIds={amsEModulesIds}
          buttonTitle={purgeTransactionButtonTitle}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          {purgeTransactionTitle}
        </AMSEModulesItemsPurgeEventsButton>
      </Menu>
    </>
  );
};

export default AMSEModuleItemsPurgeButton;
