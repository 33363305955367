import {useEffect, useState} from 'react';

interface Props {
  value: number;
}

const formatTime = (seconds: number | null) => {
  if (seconds === null) {
    return 0;
  }

  if (seconds < 0) {
    return seconds;
  }

  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const Countdown = ({value}: Props) => {
  const [counter, setCounter] = useState<number>(value);

  useEffect(() => {
    setCounter(value);
  }, [value]);

  useEffect(() => {
    const uInt = setInterval(() => setCounter((v) => v - 1), 1000);
    return () => clearInterval(uInt);
  }, []);

  return <>{formatTime(counter)}</>;
};

export default Countdown;
