import {useSnackbar} from 'notistack';
import {forwardRef, useImperativeHandle} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';

export interface ToggleCalibrationModeProps {
  onSubmitted?: () => void;
}

export interface ToggleCalibrationModeRef {
  submit: (pk: number, status: string) => void;
}

export const AMSEModuleItemToggleCalibrationMode = forwardRef<
  ToggleCalibrationModeRef,
  React.PropsWithChildren<ToggleCalibrationModeProps>
>(({onSubmitted}, ref) => {
  const {enqueueSnackbar} = useSnackbar();

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    submit: (pk, status) => submitData(pk, status),
  }));

  /**********/
  /* submit */
  /**********/
  const submitData = async (pk: number, status: string) => {
    try {
      const endpoint = `${apiBaseUrl}/ams/emodule/${pk}/calibration_mode`;
      const data = {
        enable: status,
      };
      const response = await API.patch(endpoint, data);
      const message = response?.data?.message ?? 'done';
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
  };

  return null;
});
