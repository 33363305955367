import {Dispatch} from 'redux';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  BackendResponse,
  BackendStatus,
  ConnectionStatus,
} from '../../interfaces/Backend';
import {ConfigurationResponse} from '../../interfaces/Configuration';
import {BlastMode} from '../../interfaces/GasMonitoringNode';
import {MeResponse} from '../../interfaces/Me';
import {getAppInitialState} from './reducers';
import {AppState} from './types';

export enum Actions {
  SetApp = 'SetApp',
}

const appActions = {
  setApp: (dispatch: Dispatch, data: Partial<AppState>) => {
    return dispatch({type: Actions.SetApp, data});
  },

  fetchMe: async (dispatch: Dispatch) => {
    const me = (await API.get<MeResponse>(`${apiBaseUrl}/user/me`)).data;
    return appActions.setApp(dispatch, {me});
  },

  fetchMyConfigurations: async (dispatch: Dispatch) => {
    const myConfigurations = (
      await API.get<ConfigurationResponse>(`${apiBaseUrl}/user-configuration`)
    ).data;
    return appActions.setApp(dispatch, {myConfigurations});
  },

  setMuteSounds: async (dispatch: Dispatch, muteSounds: boolean) => {
    return appActions.setApp(dispatch, {muteSounds});
  },

  setBlast: async (dispatch: Dispatch, blastMode: BlastMode | null) => {
    return appActions.setApp(dispatch, {blastMode});
  },

  submitDeactivateBlast: async (dispatch: Dispatch) => {
    try {
      const endpoint = `${apiBaseUrl}/ams/blast_mode`;
      await API.patch(endpoint, {
        enable: false,
      });
    } catch (error: any) {
      const blastMode = {
        timeout: null,
        status: false,
      };
      appActions.setApp(dispatch, {blastMode});
    }
  },

  pollBackend: async (dispatch: Dispatch) => {
    try {
      const start = performance.now();
      const backendResponse = (
        await API.get<BackendResponse>(`${apiBaseUrl}/system/poll`)
      ).data;

      const end = performance.now();
      let connection_status: ConnectionStatus;

      const delay = Math.round(end - start);
      if (delay > 1000) {
        connection_status = ConnectionStatus.VERY_SLOW;
      } else if (delay > 500) {
        connection_status = ConnectionStatus.SLOW;
      } else if (delay > 100) {
        connection_status = ConnectionStatus.GOOD;
      } else {
        connection_status = ConnectionStatus.VERY_GOOD;
      }

      const backend: BackendStatus = {
        ...backendResponse,
        connected: true,
        delay: Math.round(end - start),
        connection_status,
        messages: backendResponse?.messages,
        actions: backendResponse?.actions,
        license: true,
        blastModeStatus: backendResponse?.blastModeStatus,
        blastModeCounter: backendResponse?.blastModeCounter,
      };

      appActions.setApp(dispatch, {
        backend: backend,
        blastMode: {
          timeout: backend.blastModeCounter,
          status: backend.blastModeStatus,
        },
      });
    } catch (err: any) {
      const backend: BackendStatus = {
        version: 'unknown',
        connected: false,
        delay: 0,
        connection_status: ConnectionStatus.DISCONNECTED,
        branch: '',
        build: 0,
        blastModeStatus: false,
        blastModeCounter: null,
      };

      if (err?.response?.status === 403) {
        backend.license = false;
      }

      appActions.setApp(dispatch, {backend});
    }
  },

  clearApp: (dispatch: Dispatch) => {
    const data: AppState = {
      ...getAppInitialState(),
      companyId: null,
    };

    return dispatch({type: Actions.SetApp, data});
  },
};

export default appActions;
