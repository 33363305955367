import BarChartIcon from '@mui/icons-material/BarChart';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Crop54Icon from '@mui/icons-material/Crop54';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  useGetAssetHumanType,
  useGetAssetMachineType,
} from '../../../hooks/get-commtrac-node-type';
import {useGetShaftClearanceBatteryPercentage} from '../../../hooks/get-shaft-clearance-node-battery-percentage';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {ExportField} from '../../../interfaces/Export';
import {
  ShaftClearanceAsset,
  ShaftClearanceAssetListQuery,
  ShaftClearanceAssetListResponse,
  ShaftClearanceBarData,
  ShaftClearancePieData,
} from '../../../interfaces/ShaftClearance';
import reduxSelectors from '../../../redux/selectors';
import {getHumanReadable} from '../../../utils/macAddress';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {BatteryIcon} from '../../common/BatteryIcon';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {ResizableColumns} from '../../common/ResizableColumns';
import {Section, ShaftSelect} from '../../selectors/ShaftSelect';
import {Chart, ChartRef} from './Chart';
import ShaftClearanceAssetGridExportExcel from './ShaftClearanceAssetGridExportExcel';
import {ShaftDateSelect} from './ShaftDateSelect';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface ShaftClearanceAssetsData {
  params: ShaftClearanceAssetListQuery;
  selectedIds: string[] | null;
  viewMode: string;
  refreshInterval: number | null | undefined;
  shownFields: {
    all?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

interface Props {
  value: ShaftClearanceAssetsData;
  onChange?: (value?: ShaftClearanceAssetsData) => void;
  onLoading?: (v: boolean) => void;
}

const locationsList = [
  {id: 'surface', name: 'Surface'},
  {id: 'underground', name: 'Underground'},
];

export const getShaftClearanceAssetsData = (): any => ({
  params: {
    page: 0,
    limit: 20,
    location: undefined,
    section_id: undefined,
    shift_id: undefined,
    date: undefined,
  },
  viewMode: 'pie',
  refreshInterval: 60000,
  selectedIds: [],
  shownFields: {
    all: DEFAULT_SHOWN_FIELDS,
  },
  grid: {
    page: 0,
    pageSize: 25,
  },
  exportFields: [
    {field: 'node_commtrac_battery_voltage', hidden: false, label: 'Battery'},
    {field: 'node_name', hidden: false, label: 'Name'},
    {field: 'node_commtrac_external_id', hidden: false, label: 'Network ID'},
    {field: 'type', hidden: false, label: 'Type'},
    {field: 'an_type', hidden: false, label: 'AT Type'},
    {
      field: 'node_commtrac_strongest_cn_name',
      hidden: false,
      label: 'Strongest Node',
    },
    {field: 'node_commtrac_date', hidden: false, label: 'Timestamp'},
    {field: 'ah_shift_id', hidden: false, label: 'Shift'},
    {field: 'node_commtrac_current_zone_id', hidden: false, label: 'Section'},
    {field: 'node_commtrac_on_surface', hidden: false, label: 'Pos'},
  ],
});

const DEFAULT_SHOWN_FIELDS = [
  'select',
  'node_commtrac_battery_voltage',
  'node_name',
  'node_commtrac_external_id',
  'type',
  'ah_type_id',
  'node_commtrac_strongest_cn_name',
  'node_commtrac_date',
  'node_commtrac_current_zone_id',
  'node_commtrac_on_surface',
];

export const ShaftClearanceAssets = ({value, onChange, onLoading}: Props) => {
  const config = useMemo(() => value ?? getShaftClearanceAssetsData(), [value]);
  const chartRef = useRef<ChartRef>(null);

  const getShaftClearanceBatteryPercentage =
    useGetShaftClearanceBatteryPercentage();

  const assets = useSelector(reduxSelectors.assets.getAssets);

  const [fetchedSectionsFilterData, setFetchedSectionsData] = useState<
    Section[] | []
  >([]);
  const [fetchedSectionsFilterErrors, setFetchedSectionsFilterErrors] =
    useState<string[]>([]);
  const [fetchedSectionsFilterInProgress, setFetchedSectionsFilterInProgress] =
    useState(false);

  const fetchSectionsFilter = useCallback(async () => {
    setFetchedSectionsFilterInProgress(true);
    setFetchedSectionsFilterErrors([]);
    onLoading?.(true);

    try {
      const params = {
        location: config.params.location,
      };

      const resp = await API.get<ShaftClearancePieData>(
        `${apiBaseUrl}/shaft-clearance/asset/chart`,
        {
          params,
        }
      );

      const dataByLocation = resp?.data.data.find(
        (item) => item.category_id === 'section'
      );
      const sections: any[] = [];

      dataByLocation?.categories.map((category: any) => {
        return dataByLocation.data.find((item: any) => {
          if (item.category_id === category.id && item.value > 0) {
            sections.push({
              name: category.label,
              value: item.value,
            });
          }
        });
      });

      const newSections = assets.zones
        .filter((zone) => sections.some((it) => it.name === zone.name))
        .map((zone) => ({
          id: zone.id.toString(),
          name: zone.name,
        }));

      setFetchedSectionsData(newSections);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedSectionsFilterErrors(messages);
    } finally {
      setFetchedSectionsFilterInProgress(false);
    }
  }, [config.params.location]);

  const [fetchedAssetsData, setFetchedAssetsData] =
    useState<ShaftClearanceAssetListResponse>();
  const [fetchedAssetsErrors, setFetchedAssetsErrors] = useState<string[]>([]);
  const [fetchedAssetsInProgress, setFetchedAssetsInProgress] = useState(false);

  const fetchAssets = useCallback(async () => {
    setFetchedAssetsInProgress(true);
    setFetchedAssetsErrors([]);
    onLoading?.(true);

    try {
      const params = {
        ...config.params,
      };
      const resp = await API.get<ShaftClearanceAssetListResponse>(
        `${apiBaseUrl}/shaft-clearance/asset/grid`,
        {
          params,
        }
      );

      setFetchedAssetsData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      onLoading?.(false);
      setFetchedAssetsErrors(messages);
    }
    setFetchedAssetsInProgress(false);
    onLoading?.(false);
  }, [config.params]);

  const [fetchedAssetsPieData, setFetchedAssetsPieData] =
    useState<ShaftClearancePieData>();
  const [fetchedAssetsPieErrors, setFetchedAssetsPieErrors] = useState<
    string[]
  >([]);
  const [fetchedAssetsPieInProgress, setFetchedAssetsPieInProgress] =
    useState(false);
  const fetchAssetsPie = useCallback(async () => {
    setFetchedAssetsPieInProgress(true);
    setFetchedAssetsPieErrors([]);
    onLoading?.(true);

    try {
      const params = {
        location: config.params.location,
        section_id: config.params.section_id,
        date: config.params.date,
      };

      const resp = await API.get<ShaftClearancePieData>(
        `${apiBaseUrl}/shaft-clearance/asset/chart`,
        {
          params,
        }
      );
      setFetchedAssetsPieData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      onLoading?.(false);
      setFetchedAssetsPieErrors(messages);
    } finally {
      setFetchedAssetsPieInProgress(false);
      onLoading?.(false);
    }
  }, [config.params, config.viewMode]);

  const updateData = useCallback(async () => {
    fetchAssets();
    fetchAssetsPie();
    fetchAssetsBar();
  }, [config]);

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    updateData,
    config.refreshInterval
  );

  useEffect(() => {
    onChange?.(
      update(config, {
        refreshInterval: {
          $set: refreshInterval,
        },
      })
    );
  }, [refreshInterval]);

  const [fetchedAssetsBarData, setFetchedAssetsBarData] =
    useState<ShaftClearanceBarData>();
  const [fetchedAssetsBarErrors, setFetchedAssetsBarErrors] = useState<
    string[]
  >([]);
  const [fetchedAssetsBarInProgress, setFetchedAssetsBarInProgress] =
    useState(false);
  const fetchAssetsBar = useCallback(async () => {
    setFetchedAssetsBarInProgress(true);
    setFetchedAssetsBarErrors([]);
    onLoading?.(true);

    try {
      const params = {
        location: config.params.location,
        section_id: config.params.section_id,
        date: config.params.date,
      };

      const resp = await API.get<ShaftClearanceBarData>(
        `${apiBaseUrl}/shaft-clearance/asset/graph`,
        {
          params,
        }
      );
      setFetchedAssetsBarData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      onLoading?.(false);
      setFetchedAssetsBarErrors(messages);
    } finally {
      setFetchedAssetsBarInProgress(false);
      onLoading?.(false);
    }
  }, [config.params, config.viewMode]);

  /**
   * Data Grid
   */
  const getAssetHumanType = useGetAssetHumanType();
  const getAssetMachineType = useGetAssetMachineType();
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(
    () => fetchedAssetsData?.items ?? [],
    [fetchedAssetsData]
  );
  const columns: DataGridColumn<ShaftClearanceAsset>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        </Box>
      ),
      renderCell: ({row}) => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            checked={selectedItems.includes(String(row.node_id))}
            onChange={() => toggleSelectItem(String(row.node_id))}
          />
        </Box>
      ),
    },
    {
      field: 'node_commtrac_battery_voltage',
      headerName: 'Battery',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Low Battery">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => getShaftClearanceBatteryPercentage(row, false),
      renderCell: ({value}) => {
        if (typeof value === 'number') {
          return (
            <Tooltip title={`${value}%`}>
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BatteryIcon value={value} />
              </Box>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Unknown">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <QuestionMarkIcon />
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'node_name',
      headerName: 'Name',
      sortable: true,
      renderCell: ({row}) => {
        return <Box>{row.node_name}</Box>;
      },
    },
    {
      field: 'node_commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_mac_address) {
          return getHumanReadable(row.node_mac_address as string);
        } else {
          return row.node_commtrac_external_id;
        }
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      renderCell: ({row}) => {
        const wifiEnabled = row?.node_mac_address ? 1 : 0;
        const type = getAssetHumanType({
          commtrac_external_id: row?.node_commtrac_external_id,
          wifi_enabled: (wifiEnabled ?? null) as 0 | 1 | null,
          mc2_flag: (row?.node_mc2_flag ?? null) as 0 | 1 | null,
        });
        return <Box>{type}</Box>;
      },
    },
    {
      field: 'ah_type_id',
      headerName: 'AT Type',
      sortable: false,
      renderCell: ({row}) => {
        const wifiEnabled = row?.node_mac_address ? 1 : 0;
        const type = getAssetMachineType({
          commtrac_external_id: row?.node_commtrac_external_id,
          wifi_enabled: wifiEnabled as 0 | 1 | null,
        });
        return <Box>{type}</Box>;
      },
    },
    {
      field: 'node_commtrac_strongest_cn_name',
      headerName: 'Strongest Node',
      sortable: true,
      renderCell: ({row}) => {
        return <Box>{row.node_commtrac_strongest_cn_name}</Box>;
      },
    },
    {
      field: 'node_commtrac_date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) =>
        row.node_commtrac_date
          ? dayjs.utc(row.node_commtrac_date).format('YYYY-MM-DD HH:mm:ss')
          : '-',
    },
    {
      field: 'node_commtrac_current_zone_id',
      headerName: 'Section',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <Box>
            {assets.zones.find(
              (i) => i.id === row.node_commtrac_current_zone_id
            )?.name ?? null}
          </Box>
        );
      },
    },
    {
      field: 'node_commtrac_on_surface',
      headerName: 'Position',
      sortable: true,
      renderCell: ({row}) => {
        if (row.node_commtrac_on_surface === '1') {
          return (
            <Tooltip title="Surface">
              <Box
                key={`shaft_a_surface_${row.node_id}`}
                sx={{position: 'relative', left: 4, top: 4}}
              >
                <BarChartIcon color="success" />
              </Box>
            </Tooltip>
          );
        } else if (row.node_commtrac_on_surface === '0') {
          return (
            <Tooltip title="Underground">
              <Box
                key={`shaft_a_surface_${row.node_id}`}
                sx={{position: 'relative', left: 4, top: 4}}
              >
                <Crop54Icon color="success" />
              </Box>
            </Tooltip>
          );
        }
      },
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    const excludedFields = [
      'node_name',
      'node_commtrac_external_id',
      'type',
      'ah_type',
      'node_commtrac_strongest_cn_name',
      'node_commtrac_date',
      'node_commtrac_current_zone_id',
      'node_commtrac_on_surface',
    ];
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter(
              (col) => !!col.headerName && !excludedFields.includes(col.field)
            )
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: !shownFields?.includes(col.field),
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(config, {
        shownFields: {
          all: {$set: fields},
        },
      })
    );
  };

  // Multiple Select
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () =>
      rows.filter((item) => config.selectedIds?.includes(String(item.node_id))),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((item) => String(item.node_id)) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectItem = (id: string) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  useEffect(() => {
    fetchAssets();
    fetchAssetsPie();
    fetchAssetsBar();
  }, [config.params, config.viewMode]);

  useEffect(() => {
    fetchSectionsFilter();
  }, [config.params.location, config.viewMode]);

  const onChangeFilter = (name: string, key: string) => {
    const val =
      key === 'location'
        ? name
        : assets.zones.find((zone) => zone.name === name)?.id ?? null;

    const updateConfig = {
      params: {
        [key]: {$set: val}, // Ensure this is correct
      },
      viewMode: {
        $set: key === 'location' ? 'pie' : 'bar',
      },
    };

    onChange?.(update(config, updateConfig));
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Backdrop
        open={
          fetchedAssetsPieInProgress ||
          fetchedAssetsInProgress ||
          fetchedAssetsBarInProgress
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedAssetsErrors.map((error, idx) => (
        <Alert key={`error-aa-${idx}`} severity="error" onClose={fetchAssets}>
          {error}
        </Alert>
      ))}
      {fetchedAssetsPieErrors.map((error, idx) => (
        <Alert
          key={`error-ap-${idx}`}
          severity="error"
          onClose={fetchAssetsPie}
        >
          {error}
        </Alert>
      ))}
      {fetchedAssetsBarErrors.map((error, idx) => (
        <Alert
          key={`error-ab-${idx}`}
          severity="error"
          onClose={fetchAssetsBar}
        >
          {error}
        </Alert>
      ))}
      {fetchedSectionsFilterErrors.map((error, idx) => (
        <Alert
          key={`error-sb-${idx}`}
          severity="error"
          onClose={() => fetchSectionsFilter()}
        >
          {error}
        </Alert>
      ))}
      <Box p={2} minWidth={800}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={40}
          gap={2}
        >
          <Box display="flex" flexGrow={1} minWidth={800} gap={1}>
            <ShaftSelect
              items={locationsList}
              value={value.params.location}
              size="small"
              nullLabel="All Locations"
              label="Location"
              fullWidth
              onChange={(v) => {
                const location = v ?? undefined;
                onChange?.(
                  update(config, {
                    params: {
                      location: {
                        $set: location,
                      },
                      section_id: {$set: undefined},
                      shift_id: {$set: undefined},
                    },
                    viewMode: {$set: 'pie'},
                  })
                );
              }}
            />
            <ShaftSelect
              items={
                !fetchedSectionsFilterInProgress
                  ? fetchedSectionsFilterData
                  : []
              }
              value={value.params.section_id?.toString()}
              size="small"
              nullLabel="All Sections"
              label="Section"
              fullWidth
              onChange={(v) => {
                const sectionId = v ? +v : undefined;

                const updateConfig = {
                  params: {
                    section_id: {
                      $set: sectionId,
                    },
                    shift_id: {$set: undefined},
                  },
                  ...(sectionId === undefined
                    ? {
                        viewMode: {
                          $set: 'pie',
                        },
                      }
                    : {
                        viewMode: {
                          $set: 'bar',
                        },
                      }),
                };
                onChange?.(update(config, updateConfig));
              }}
            />
            <ShaftDateSelect
              value={config.params.date ? config.params.date : undefined}
              renderInput={{
                label: 'Date',
                size: 'small',
                fullWidth: true,
              }}
              onChange={(v) => {
                const date = v ? v : undefined;
                const updateConfig = {
                  params: {
                    date: {
                      $set: date
                        ? dayjs(date).format('YYYY-MM-DD HH:mm:ss').toString()
                        : undefined,
                    },
                  },
                };
                onChange?.(update(config, updateConfig));
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            height="100%"
          >
            <ButtonGroup>
              <Button size="small">
                <ShaftClearanceAssetGridExportExcel value={config.params} />
              </Button>

              <Button
                onClick={() => {
                  fetchAssets();
                  fetchAssetsPie();
                  fetchAssetsBar();
                }}
              >
                <Tooltip title="Refresh">
                  <RefreshIcon />
                </Tooltip>
              </Button>

              <AutoRefreshSelect
                value={config?.refreshInterval as number | null}
                onChange={setRefreshInterval}
              />

              <Button onClick={() => {}}>
                <PrintIcon onClick={() => dataGridRef.current?.printTable()} />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <ResizableColumns
        left={
          <Box height="100%" gap={2}>
            <Chart
              ref={chartRef}
              view={config.viewMode}
              pieData={fetchedAssetsPieData}
              barData={fetchedAssetsBarData}
              params={config.params}
              isMinersorAsset="miners"
              isLoading={
                fetchedAssetsBarInProgress || fetchedAssetsPieInProgress
              }
              onChangeFilter={(v: string, key: string) => {
                onChangeFilter(v, key);
              }}
              onChangeChartViewMode={(v: string) => {
                onChange?.(
                  update(config, {
                    viewMode: {
                      $set: v,
                    },
                  })
                );
              }}
            />
          </Box>
        }
        onResizeStop={() => chartRef.current?.updateChart?.()}
      >
        <Box height="100%">
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={fetchedAssetsInProgress}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};
