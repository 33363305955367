import {CheckOutlined, DisabledByDefaultSharp} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {useState} from 'react';

import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {
  GasMonitoringNode,
  SentroType,
} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import AMSSensorItemPurgeModal from '../../ams-sensor/AMSSensorItemPurgeModal';
import AccessControl from '../../common/AccessControl';
import {getHasPermission} from '../../common/AccessControl/utils';
import AMSSensorItemActivationModal from './AMSSensorItemActivationModal';
import AMSSensorItemDisableEventsModal from './AMSSensorItemDisableEventsModal';
import AMSSensorItemStandownEventsModal from './AMSSensorItemStandownEventsModal';

interface Props {
  item: GasMonitoringNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onCancel?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation'
  ) => void;
}

const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
} as const;

type Action =
  | 'checkin'
  | 'activation'
  | 'purgeDevice'
  | 'purgeData'
  | 'checkInOut'
  | 'disable_events'
  | 'enable_events'
  | 'standDown';

const AMSSensorItemActionsButton = ({
  item,
  onSubmitted,
  onPurged,
  onCancel,
  onOpenHistory,
}: Props) => {
  const reduxAppDispatch = useAppDispatch();
  const loadAssets = () => {
    reduxAppDispatch(reduxActions.assets.fetchAmsEmodules);
    reduxAppDispatch(reduxActions.assets.fetchGasMonitorNodes);
  };

  const me = useAppSelector(({app}) => app.me);
  const [action, setAction] = useState<Action>();
  const isPurgeAction = action === 'purgeDevice' || action === 'purgeData';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);
  const menuOpenMore = Boolean(anchorElMore);
  const historyAvailable = !!item?.emodules?.length;
  const isSentro8 = item?.sentro_type === SentroType.SENTRO_8;
  const emoduleChannelLabel = useAppSelector(
    ({assets}) => assets.sentro8ChannelLabel
  );

  const checkMenuContent = () => {
    const menuItemCount = document.querySelectorAll(
      '.sensor_menu_list li'
    ).length;
    if (menuItemCount === 0) {
      const menuElements = document.getElementsByClassName('sensor_menu_list');
      for (let i = 0; i < menuElements.length; i++) {
        const element = menuElements[i] as HTMLElement;
        element.style.display = 'none';
      }
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="start" gap={0.5}>
        <Box position="relative">
          {historyAvailable ? (
            <Box>
              <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
                History
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onBackdropClick={() => setAnchorEl(null)}
              >
                {!isSentro8 ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        loadAssets();
                        onOpenHistory?.(item?.emodules?.[0].id, 'amsLongTerm');
                        onCancel?.();
                      }}
                    >
                      Long Term History Report
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        loadAssets();
                        onOpenHistory?.(item?.emodules?.[0].id, 'amsShortTerm');
                        onCancel?.();
                      }}
                    >
                      Short Term History Reports
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        loadAssets();
                        onOpenHistory?.(item.id, 'amsLocation');
                        onCancel?.();
                      }}
                    >
                      Location Report
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <Accordion
                        elevation={0}
                        disableGutters
                        sx={{
                          margin: 0,
                          backgroundColor: 'transparent',
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            margin: '-12px 0',
                            padding: 0,
                            minHeight: 0,
                          }}
                        >
                          Short Term History Report
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {item?.emodules?.map((emodule, i) => (
                            <MenuItem
                              onClick={() => {
                                loadAssets();
                                onOpenHistory?.(emodule?.id, 'amsShortTerm');
                                onCancel?.();
                              }}
                              key={i}
                            >
                              Channel{' '}
                              {emoduleChannelLabel && emodule.channel
                                ? emoduleChannelLabel[emodule.channel]
                                : ''}
                            </MenuItem>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </MenuItem>
                    <MenuItem>
                      <Accordion
                        elevation={0}
                        disableGutters
                        sx={{
                          backgroundColor: 'transparent',
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            margin: '-12px 0',
                            padding: 0,
                            minHeight: 0,
                          }}
                          classes={{
                            content: 'custom-accordion',
                          }}
                        >
                          Long Term History Report
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: 0,
                            margin: 0,
                            marginTop: '.5rem',
                          }}
                        >
                          {item?.emodules?.map((emodule, i) => (
                            <MenuItem
                              onClick={() => {
                                loadAssets();
                                onOpenHistory?.(emodule?.id, 'amsLongTerm');
                                onCancel?.();
                              }}
                              key={i}
                            >
                              Channel{' '}
                              {emoduleChannelLabel && emodule.channel
                                ? emoduleChannelLabel[emodule.channel]
                                : ''}
                            </MenuItem>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </MenuItem>
                  </>
                )}
              </Menu>
            </Box>
          ) : null}
        </Box>
        {(me?.type_id !== 5 ||
          item.type_id === 40 ||
          item.type_id === 20 ||
          item.type_id === 130 ||
          item.type_id === 44 ||
          getHasPermission(me.permissions, ['post::/purge']) ||
          getHasPermission(me.permissions, [
            'patch::/ams/:id(\\d+)/stand_down',
          ]) ||
          getHasPermission(me.permissions, ['patch::/ams/:id(\\d+)'])) && (
          <IconButton
            onClick={(event) => {
              setAnchorElMore(event.currentTarget);
              setTimeout(checkMenuContent, 0);
            }}
            size="small"
            color="primary"
          >
            <MoreHorizIcon />
          </IconButton>
        )}
        <Menu
          className="sensor_menu_list"
          anchorEl={anchorElMore}
          open={menuOpenMore}
          onBackdropClick={() => setAnchorElMore(null)}
        >
          {(item.type_id === 40 ||
            item.type_id === 20 ||
            item.type_id === 130 ||
            item.type_id === 44) && (
            <>
              {item?.disable_event === 0 ? (
                <MenuItem
                  onClick={() => {
                    setAction('disable_events');
                    setAnchorElMore(null);
                  }}
                >
                  <DisabledByDefaultSharp fontSize="small" sx={{mr: 1.5}} />
                  Disable Events
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    setAction('enable_events');
                    setAnchorElMore(null);
                  }}
                >
                  <CheckOutlined fontSize="small" sx={{mr: 1.5}} />
                  Enable Events
                </MenuItem>
              )}
            </>
          )}

          <AccessControl
            permissions={['patch::/ams/:id(\\d+)/stand_down']}
            accessChecker={() => !!item?.ava}
          >
            <MenuItem
              onClick={() => {
                setAction('standDown');
                setAnchorElMore(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Stand Down
            </MenuItem>
          </AccessControl>
          <AccessControl permissions={['patch::/ams/:id(\\d+)']}>
            <MenuItem
              onClick={() => {
                setAction('activation');
                setAnchorEl(null);
              }}
            >
              {item?.status === 'active' ? (
                <>
                  <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                  DeActivate
                </>
              ) : (
                <>
                  <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                  Activate
                </>
              )}
            </MenuItem>
          </AccessControl>
          <AccessControl permissions={['post::/purge']}>
            <MenuItem
              onClick={() => {
                setAction('purgeDevice');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Device
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction('purgeData');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Transaction
            </MenuItem>
          </AccessControl>
        </Menu>
      </Box>
      {action === 'activation' ? (
        <AMSSensorItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <AMSSensorItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}

      {(action === 'disable_events' || action === 'enable_events') && (
        <AMSSensorItemDisableEventsModal
          item={item}
          action={action}
          open={action === 'disable_events' || action === 'enable_events'}
          onClose={() => {
            setAction(undefined);
          }}
          onSubmitted={onSubmitted}
        />
      )}

      {action === 'standDown' && (
        <AMSSensorItemStandownEventsModal
          item={item}
          open={action === 'standDown'}
          onClose={() => {
            setAction(undefined);
          }}
          onSubmitted={onSubmitted}
        />
      )}
    </>
  );
};

export default AMSSensorItemActionsButton;
