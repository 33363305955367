import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import NodeItemsPurgeEventsButton from './NodeItemsPurgeEventsButton';
import NodeItemsPurgeNodeButton from './NodeItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  cnIds?: number[];
  wifiIds?: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const NodeItemsPurgeButton = <T extends ComponentType = typeof Button>({
  cnIds,
  wifiIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  const cnCount = useMemo(() => {
    return cnIds?.length ?? 0;
  }, [cnIds]);

  const wifiCount = useMemo(() => {
    return wifiIds?.length ?? 0;
  }, [wifiIds]);

  const cnString = useMemo(() => {
    return `${cnCount} CN${cnCount > 1 ? 's' : ''}`;
  }, [cnCount]);

  const wifiString = useMemo(() => {
    return `${wifiCount} WiFi node${wifiCount > 1 ? 's' : ''}`;
  }, [wifiCount]);

  const purgeAllTitle = useMemo(() => {
    if (cnCount > 0 && wifiCount > 0) {
      return `Device (${cnString}, ${wifiString})`;
    } else if (cnCount > 0) {
      return `Device (${cnString})`;
    } else if (wifiCount > 0) {
      return `Device (${wifiString})`;
    } else {
      return '';
    }
  }, [cnCount, wifiCount]);

  const purgeTransactionTitle = useMemo(() => {
    if (cnCount > 0 && wifiCount > 0) {
      return `Transactions (${cnString}, ${wifiString})`;
    } else if (cnCount > 0) {
      return `Transactions (${cnString})`;
    } else if (wifiCount > 0) {
      return `Transactions (${wifiString})`;
    } else {
      return '';
    }
  }, [cnCount, wifiCount]);

  const purgeAllButtonTitle = useMemo(() => {
    if (cnCount > 0 && wifiCount > 0) {
      return `Purge Device (${cnString}, ${wifiString})`;
    } else if (cnCount > 0) {
      return `Purge Device (${cnString})`;
    } else if (wifiCount > 0) {
      return `Purge Device (${wifiString})`;
    } else {
      return '';
    }
  }, [cnCount, wifiCount]);

  const purgeTransactionButtonTitle = useMemo(() => {
    if (cnCount > 0 && wifiCount > 0) {
      return `Purge Transactions (${cnString}, ${wifiString})`;
    } else if (cnCount > 0) {
      return `Purge Transactions (${cnString})`;
    } else if (wifiCount > 0) {
      return `Purge Transactions (${wifiString})`;
    } else {
      return '';
    }
  }, [cnCount, wifiCount]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <NodeItemsPurgeNodeButton
          cnIds={cnIds}
          wifiIds={wifiIds}
          buttonTitle={purgeAllButtonTitle}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          {purgeAllTitle}
        </NodeItemsPurgeNodeButton>

        <NodeItemsPurgeEventsButton
          cnIds={cnIds}
          wifiIds={wifiIds}
          buttonTitle={purgeTransactionButtonTitle}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          {purgeTransactionTitle}
        </NodeItemsPurgeEventsButton>
      </Menu>
    </>
  );
};

export default NodeItemsPurgeButton;
