import {Box, BoxProps, Tooltip} from '@mui/material';
import {get} from 'lodash';
import {useMemo} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {label} from '../../interfaces/Backend';
import reduxSelectors from '../../redux/selectors';
import {connectionStatusIcons} from '../../utils/event-icons';

interface Props extends BoxProps {
  short?: boolean;
}

const Status = ({short = false, ...props}: Props) => {
  const app = useAppSelector(reduxSelectors.app.getApp);

  const backend = useMemo(() => {
    return app.backend;
  }, [app.backend]);

  const be_version = useMemo(() => {
    const version = backend?.version ?? 'unknown';
    const branch = backend?.branch ?? '';
    const build = backend?.build ?? 0;

    return (
      version.replace(/^(\d+)[.](\d+)[.](\d+)$/is, `$1.$2.$3.${build}`) +
      branch
        .replace(/^((d)evelopment\/.+|(s)taging\/.+)$/is, `$2$3`)
        .replace('S', 'P')
    );
  }, [backend?.version, backend?.branch, backend?.build]);

  const fe_version = useMemo(() => {
    const version = process.env.REACT_APP_VERSION ?? 'unknown';
    const branch = get(window, 'strata.version.fe_branch', 'unknown');
    const build = get(window, 'strata.version.fe_build', 0);

    return (
      version.replace(/^(\d+)[.](\d+)[.](\d+)$/is, `$1.$2.$3.${build}`) +
      branch
        .replace(/^((d)evelopment\/.+|(s)taging\/.+)$/is, `$2$3`)
        .replace('S', 'P')
    );
  }, [process.env.REACT_APP_VERSION]);

  if (short) {
    return (
      <Box {...props} sx={{display: 'inline-flex', padding: '3px'}}>
        <Tooltip
          title={`response: ${backend?.delay}ms, ${backend?.connection_status ? label[backend?.connection_status] : 'unknown'}\nfrontend: ${fe_version}, backend: ${be_version}`}
          placement="top-end"
        >
          {connectionStatusIcons[backend?.connection_status ?? 'disconnected']}
        </Tooltip>
      </Box>
    );
  } else {
    return (
      <Box {...props} sx={{display: 'inline-flex', padding: '3px'}}>
        <Tooltip
          title={`response: ${backend?.delay}ms, ${backend?.connection_status ? label[backend?.connection_status] : 'unknown'}`}
          placement="top-end"
        >
          {connectionStatusIcons[backend?.connection_status ?? 'disconnected']}
        </Tooltip>
        <Tooltip
          title={`frontend version: ${fe_version}, backend version: ${be_version}`}
          placement="top-end"
        >
          <Box
            sx={{margin: '0 0 0 8px'}}
          >{`f:${fe_version}, b:${be_version}`}</Box>
        </Tooltip>
      </Box>
    );
  }
};

export default Status;
