import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  ShaftClearanceAssetsReportItem,
  ShaftClearanceAssetsReportQuery,
  ShaftClearanceAssetsReportResponse,
} from '../../../interfaces/ShaftClearance';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
// import {DateSelect} from '../../selectors/DateSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import ShaftClearanceAssetsExportExcel from './ShaftClearanceAssetsExportExcel';

const DEFAULT_SHOWN_FIELDS = [
  'name',
  'wifi_enabled',
  'network_id',
  'mac_address',
  'type',
  'timestamp',
  'date',
  'shift_id',
  'shift',
  'section',
  'position',
  'latitude',
  'longitude',
  'strongest_node',
];

export interface ShaftClearanceAssetsReportData {
  refreshInterval?: number | null;
  params?: {
    //page?: number;
    //limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
    //date_end?: string;
  };
}

export const getShaftClearanceAssetsReportData =
  (): ShaftClearanceAssetsReportData => ({
    params: {},
  });

export const ShaftClearanceAssetsReport = () => {
  const [config, setConfig] = useState(getShaftClearanceAssetsReportData);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<ShaftClearanceAssetsReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<ShaftClearanceAssetsReportQuery>(
    () =>
      ({
        //page: config.params?.page ?? 0,
        //limit: config.params?.limit ?? 25,
        order: config.params?.order ?? null,
        dir: config.params?.dir ?? null,
        //date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      }) as ShaftClearanceAssetsReportQuery,
    [config]
  );

  const fetchData = useCallback(
    async (params: ShaftClearanceAssetsReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/shaft-clearance/assets`;
        const resp = await API.get<ShaftClearanceAssetsReportResponse>(
          endpoint,
          {
            params,
          }
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<ShaftClearanceAssetsReportItem>[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'network_id',
      headerName: 'Network ID',
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) =>
        row.timestamp
          ? dayjs.utc(row.timestamp).format('YYYY-MM-DD HH:mm:ss')
          : '-',
    },
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      valueGetter: ({row}) =>
        row.date ? dayjs.utc(row.date).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
    {
      field: 'shift',
      headerName: 'Shift',
      sortable: true,
    },
    {
      field: 'section',
      headerName: 'Section',
      sortable: true,
    },
    {
      field: 'position',
      headerName: 'Position',
      sortable: true,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      sortable: true,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      sortable: true,
    },
    {
      field: 'strongest_node',
      headerName: 'Strongest Node',
      sortable: true,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={1}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        Shaft Clearance Assets Report
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            {/* <Box minWidth={400}>
              <DateSelect
                value={dayjs(params?.date_end).toDate()}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...params,
                          date_end: v
                            ? dayjs(v).format('YYYY-MM-DD')
                            : undefined,
                          page: 0,
                        },
                      },
                    })
                  );
                }}
              />
            </Box> */}
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Button size="small">
                <ShaftClearanceAssetsExportExcel
                // reportDate={params.date_end}
                ></ShaftClearanceAssetsExportExcel>
              </Button>

              <Button size="small" onClick={() => params && fetchData(params)}>
                <RefreshIcon />
              </Button>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Button
                size="small"
                onClick={() => dataGridRef.current?.printTable()}
              >
                <PrintIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        size="small"
        pageSize={100000}
        sortBy={
          params?.order
            ? {
                field: params?.order,
                dir: params?.dir === 'DESC' ? 'desc' : 'asc',
              }
            : null
        }
        sortingMode="client"
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        onSort={(v) => {
          if (v) {
            setConfig?.(
              update(config, {
                params: {
                  $set: {
                    ...config.params,
                    order: v.field,
                    dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                  },
                },
              })
            );
          }
        }}
        onShownFieldsChange={setShownFields}
      ></DataGrid>
    </Box>
  );
};
