import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {cloneDeep} from 'lodash';
import {enqueueSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {MeResponse} from '../../interfaces/Me';
import {MyAccountInputs, UserItemResponse} from '../../interfaces/User';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';

interface Props {
  onClose: VoidFunction;
}

const myAccountValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Field is required')
    .email('Please enter valid email address'),
  password: yup
    .string()
    .nullable()
    .min(6, 'Password is too short - should be 6 characters minimum.')
    .matches(/(?=.[A-Z])/, 'Password should have at least 1 Uppercase Letter.')
    .matches(/(?=.\d)/, 'Password should have at least 1 Number.')
    .matches(/(?=.\W)/, 'Password should have at least 1 Special Character.'),
  rePassword: yup
    .string()
    .nullable()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const MyAccount = ({onClose}: Props) => {
  const me = useAppSelector(({app}) => app.me);

  const [fetchedData, setFetchedData] = useState<MeResponse | null>(
    cloneDeep(me)
  );
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [editingPassword, setEditingPassword] = useState(false);

  const reduxDispatch = useAppDispatch();

  const myAccountFormik = useFormik({
    initialValues: {
      email: fetchedData?.email,
      password: undefined,
      rePassword: undefined,
    },
    validationSchema: myAccountValidationSchema,
    onSubmit: async ({email, password, rePassword}) =>
      updateData({
        email,
        username: fetchedData?.username,
        password,
        rePassword,
      }),
  });

  const allowPasswordChange = useMemo(() => {
    return !fetchedData?.ldap_user;
  }, [fetchedData]);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<MeResponse>(`${apiBaseUrl}/user/me`);
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setApiErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    myAccountFormik.setValues({
      email: fetchedData?.email,
      password: undefined,
      rePassword: undefined,
    });
  }, [fetchedData?.email]);

  const updateData = async (data: Partial<MyAccountInputs>) => {
    setUpdateInProgress(true);

    try {
      await API.patch<UserItemResponse>(
        `${apiBaseUrl}/user/${fetchedData?.id}`,
        data
      );
      reduxDispatch(reduxActions.app.fetchMe);
      onClose();
      enqueueSnackbar('User email updated successfully', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setApiErrors(messages);
    }

    setUpdateInProgress(false);
  };

  return (
    <>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {apiErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{my: 2}}>
          {error}
        </Alert>
      ))}

      <Box my={4}>
        <TextField
          value={fetchedData?.name}
          fullWidth
          name="name"
          label="Name"
          size="small"
          disabled
        />
      </Box>

      <Box my={4}>
        <TextField
          value={fetchedData?.username}
          fullWidth
          name="username"
          label="Username"
          size="small"
          disabled
        />
      </Box>

      <Box my={4}>
        <TextField
          value={myAccountFormik.values.email}
          fullWidth
          name="email"
          label="Email"
          size="small"
          error={
            !!myAccountFormik.touched.email && !!myAccountFormik.errors.email
          }
          helperText={
            myAccountFormik.touched.email && myAccountFormik.errors.email
          }
          onChange={myAccountFormik.handleChange}
        />
      </Box>

      {allowPasswordChange && !editingPassword && (
        <Box my={4}>
          <Button variant="outlined" onClick={() => setEditingPassword(true)}>
            Change Password
          </Button>
        </Box>
      )}

      {allowPasswordChange && editingPassword && (
        <>
          <Box my={4}>
            <TextField
              value={myAccountFormik.values.password}
              fullWidth
              name="password"
              label="New Password"
              size="small"
              type="password"
              error={
                !!myAccountFormik.touched.password &&
                !!myAccountFormik.errors.password
              }
              helperText={
                myAccountFormik.touched.password &&
                myAccountFormik.errors.password
              }
              onChange={myAccountFormik.handleChange}
            />
          </Box>

          <Box my={4}>
            <TextField
              value={myAccountFormik.values.rePassword}
              fullWidth
              name="rePassword"
              label="Confirm New Password"
              size="small"
              type="password"
              error={
                !!myAccountFormik.touched.rePassword &&
                !!myAccountFormik.errors.rePassword
              }
              helperText={
                myAccountFormik.touched.rePassword &&
                myAccountFormik.errors.rePassword
              }
              onChange={myAccountFormik.handleChange}
            />
          </Box>
        </>
      )}

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={onClose}>Close</Button>

        <LoadingButton
          sx={{ml: 1}}
          type="submit"
          variant="contained"
          color="error"
          loading={updateInProgress}
          onClick={() => myAccountFormik.handleSubmit()}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default MyAccount;
