import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {ShaftClearanceEmployeesListQuery} from '../../../interfaces/ShaftClearance';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props {
  value: ShaftClearanceEmployeesListQuery;
}

const ShaftClearanceEmployeeGridExportExcel = ({value}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'ah_first_name', label: 'First Name', hidden: false},
    {field: 'ah_last_name', label: 'Last Name', hidden: false},
    {field: 'node_name', label: 'Name', hidden: false},
    {field: 'ah_commtrac_external_id', label: 'Network ID', hidden: false},
    {field: 'node_mc2_flag', label: 'Type', hidden: false},
    {field: 'node_commtrac_date', label: 'Timestamp', hidden: false},
    {field: 'ah_shift_id', label: 'Shift', hidden: false},
    {field: 'node_commtrac_current_zone_name', label: 'Section', hidden: false},
    {field: 'node_commtrac_on_surface', label: 'Position', hidden: false},
    {
      field: 'node_commtrac_strongest_cn_name',
      label: 'Strongest Node',
      hidden: false,
    },
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);

    try {
      const params = {
        export: 'excel',
        fields,
        date: value.date,
        order: value.order,
        dir: value.dir,
        location: value.location,
        section_id: value.section_id,
      };

      const resp = await API.get(
        `${apiBaseUrl}/shaft-clearance/employee/grid`,
        {
          params,
          responseType: 'blob',
        }
      );
      saveFile(
        resp.data,
        `ShaftClearanceEmployee-${value.date || new Date().toISOString().slice(0, 10)}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <FileDownloadIcon onClick={() => checkFields()}></FileDownloadIcon>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={3}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default ShaftClearanceEmployeeGridExportExcel;
