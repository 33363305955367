/* eslint-disable no-bitwise */
import AdjustIcon from '@mui/icons-material/Adjust';
import BarChartIcon from '@mui/icons-material/BarChart';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BuildIcon from '@mui/icons-material/Build';
import ChatIcon from '@mui/icons-material/Chat';
import Crop54Icon from '@mui/icons-material/Crop54';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import _ from 'lodash';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import {useEffect, useMemo, useRef} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useGetCommtracNodeBatteryPercentage} from '../../../hooks/get-commtrac-node-battery-percentage';
import {useGetCommtracNodeType} from '../../../hooks/get-commtrac-node-type';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {BroadcastMessage} from '../../../interfaces/BroadcastMessage';
import {
  CommtracNode,
  CommtracNodeListQuery,
  CommtracNodeListResponse,
} from '../../../interfaces/CommtracNode';
import {ExportField} from '../../../interfaces/Export';
import {EmployeeEmergencyResponse} from '../../../utils/commtrac-nodes';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {eventIconsLegacy} from '../../../utils/event-icons';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import {BatteryIcon} from '../../common/BatteryIcon';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import CommtracNodeItemsEditButton from '../../commtrac-nodes/buttons/CommtracNodeItemsEditButton';
import CommtracNodeItemsPurgeButton from '../../commtrac-nodes/buttons/CommtracNodeItemsPurgeButton';
import CommtracNodeBroadcastReportFrequencyButton from '../../commtrac-nodes/CommtracNodeBroadcastReportFrequencyButton';
import CommtracNodeImportExportButton from '../../commtrac-nodes/CommtracNodeImportExportButton';
import CommtracNodeItemAssetAcknowledgeButton from '../../commtrac-nodes/CommtracNodeItemAssetAcknowledgeButton';
import CommtracNodeItemMinerAcknowledgeButton from '../../commtrac-nodes/CommtracNodeItemMinerAcknowledgeButton';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {getCommtracNodesReportData} from '../ConnectView/CommtracNodesReport';
import {StatusSelect} from './StatusSelect';
import {TypeSelect} from './TypeSelect';

interface Props {
  value?: CommtracNodesReportData;
  fetchedData?: CommtracNodeListResponse;
  loading?: boolean;
  selectedBroadcastMessage?: BroadcastMessage;
  onChange?: (value?: CommtracNodesReportData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
  onChangeIsShownGroupChat?: (value: boolean) => void;
  onUnassignedEmployeesOpen?: () => void;
  onUnassignedAssetsOpen?: () => void;
}

export interface CommtracNodesReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<CommtracNodeListQuery>;
  selectedIds: number[] | null;
  shownFields: {
    all?: string[];
    miners?: string[];
    assets?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

const EmployeeAssetsGridReport = ({
  value,
  fetchedData,
  loading,
  selectedBroadcastMessage,
  onChange,
  onOpenItem,
  onOpenHistory,
  onRefresh,
  // onChangeLocationCoordinates,
  onChangeIsShownGroupChat,
  onUnassignedEmployeesOpen,
  onUnassignedAssetsOpen,
}: Props) => {
  const config = useMemo(() => {
    const defaultConfig = value ?? getCommtracNodesReportData();
    if (_.isEmpty(defaultConfig?.params?.node_type)) {
      defaultConfig.params.node_type?.push('asset');
      defaultConfig.params.node_type?.push('miner');
    }
    return defaultConfig;
  }, [value]);

  const isAssetsOnly = useMemo(
    () => isEqual(config.params.node_type, ['asset']),
    [config.params.node_type]
  );

  const isEmployeesOnly = useMemo(
    () => isEqual(config.params.node_type, ['miner']),
    [config.params.node_type]
  );

  /*************/
  /* data grid */
  /*************/
  const myPermissions = useAppSelector(({app}) => app.me?.permissions);
  const assetHumanTypes = useAppSelector(
    ({assets}) => assets.asset_human_types
  );
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const zones = useAppSelector(({assets}) => assets.zones);
  const shifts = useAppSelector(({assets}) => assets.shifts);
  const getCommtracNodeType = useGetCommtracNodeType();
  const getCommtracNodeBatteryPercentage =
    useGetCommtracNodeBatteryPercentage();
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];

  const columns: DataGridColumn<CommtracNode>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   sortable: true,
    // },
    {
      field: 'actions',
      headerName: 'Edit',
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.commtrac_ack === '0' || !row.employee_asset_id) {
            return (
              <AccessControl
                permissions={[
                  'patch::/commtrac-node/:id(\\d+)/asset/acknowledge',
                ]}
              >
                <CommtracNodeItemMinerAcknowledgeButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'warning',
                  }}
                  onSubmitted={(item) => {
                    onRefresh?.();
                    if (item.employee_asset_id) {
                      if (item.type === 'miner') {
                        onOpenItem?.(item.employee_asset_id, 'employee');
                      } else if (item.type === 'asset') {
                        onOpenItem?.(item.employee_asset_id, 'asset');
                      }
                    }
                  }}
                >
                  <WarningAmberIcon />
                </CommtracNodeItemMinerAcknowledgeButton>
              </AccessControl>
            );
          } else if (row.employee_asset_id) {
            return (
              <CommtracNodeItemsEditButton
                component={IconButton}
                componentProps={{
                  color: 'primary',
                }}
                item={row}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              >
                <EditIcon />
              </CommtracNodeItemsEditButton>
            );
          }
        } else if (row.type === 'asset') {
          if (row.commtrac_ack === '0' || !row.employee_asset_id) {
            return (
              <AccessControl
                permissions={[
                  'patch::/commtrac-node/:id(\\d+)/asset/acknowledge',
                ]}
              >
                <CommtracNodeItemAssetAcknowledgeButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'warning',
                  }}
                  onSubmitted={(item) => {
                    onRefresh?.();
                    if (item.employee_asset_id) {
                      onOpenItem?.(item.employee_asset_id, 'asset');
                    }
                  }}
                >
                  <WarningAmberIcon />
                </CommtracNodeItemAssetAcknowledgeButton>
              </AccessControl>
            );
          } else if (row.employee_asset_id) {
            return (
              <CommtracNodeItemsEditButton
                component={IconButton}
                componentProps={{
                  color: 'primary',
                }}
                item={row}
                onOpenItem={() => {
                  if (row.employee_asset_id) {
                    onOpenItem?.(row.employee_asset_id, 'asset');
                  }
                }}
                onOpenHistory={() => {
                  if (row.employee_asset_id) {
                    onOpenHistory?.(row.employee_asset_id, 'asset');
                  }
                }}
              >
                <EditIcon />
              </CommtracNodeItemsEditButton>
            );
          }
        }
      },
    },
    {
      field: 'chat',
      hidden:
        !myPermissions?.includes('get::/commtrac-node/:id(\\d+)/message') ||
        isAssetsOnly,
      headerName: 'Chat',
      doNotExport: true,
      type: 'actions',
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => {
        if (row.type === 'miner' && !row.wifi_enabled) {
          return (
            <IconButton
              color="primary"
              disabled={!row.employee_asset_id}
              onClick={() => {
                if (row.employee_asset_id) {
                  onOpenItem?.(row.employee_asset_id, 'employee', 'chat');
                }
              }}
            >
              <ChatIcon fontSize="small" />
            </IconButton>
          );
        }
      },
    },
    {
      field: 'battery',
      headerName: 'Battery',
      renderHeader: () => (
        <Tooltip title="Low Battery">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      width: 50,
      valueGetter: ({row}) => getCommtracNodeBatteryPercentage(row),
      renderCell: ({value}) => {
        if (typeof value === 'number') {
          return (
            <Tooltip title={`${value}%`}>
              <Box>
                <BatteryIcon value={value} />
              </Box>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Unknown">
              <QuestionMarkIcon />
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'alarm',
      headerName: 'Alarm',
      hidden: isAssetsOnly,
      renderHeader: () => (
        <Tooltip title="Alarm">
          <ReportProblemIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      width: 40,
      valueGetter: ({row}) => !!row.e_201,
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_201) {
            return (
              <Tooltip title="Employee Alarm">
                {eventIconsLegacy.commtracNode.alarm}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'no_location',
      headerName: 'No Location',
      renderHeader: () => (
        <Tooltip title="No Location">
          <WifiIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      width: 40,
      valueGetter: ({row}) => {
        if (row.e_202 || row.e_301) {
          return 'no';
        } else if (row.e_221 || row.e_321) {
          return 'many';
        } else if (row.e_226 || row.e_326) {
          return 'invalid';
        }
      },
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_221) {
            return (
              <Tooltip title="Many Location">
                {eventIconsLegacy.commtracNode.manyLocation}
              </Tooltip>
            );
          } else if (row.e_202) {
            return (
              <Tooltip title="No Location">
                {eventIconsLegacy.commtracNode.noLocation}
              </Tooltip>
            );
          } else if (row.e_226) {
            return (
              <Tooltip title="Location Derived">
                {eventIconsLegacy.commtracNode.derivedLocation}
              </Tooltip>
            );
          }
        } else if (row.type === 'asset') {
          if (row.e_321) {
            return (
              <Tooltip title="Many Location">
                {eventIconsLegacy.commtracNode.manyLocation}
              </Tooltip>
            );
          } else if (row.e_301) {
            return (
              <Tooltip title="No Location">
                {eventIconsLegacy.commtracNode.noLocation}
              </Tooltip>
            );
          } else if (row.e_326) {
            return (
              <Tooltip title="Location Derived">
                {eventIconsLegacy.commtracNode.derivedLocation}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'motion',
      headerName: 'Motion',
      hidden: isAssetsOnly,
      renderHeader: () => (
        <Tooltip title="Motion">
          <PersonRemoveIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      width: 40,
      valueGetter: ({row}) => (row.e_233 ? row.motion_sensor_value : -1),
      renderCell: ({row, value}) => {
        if (row.type === 'miner') {
          if (value === 0) {
            return (
              <Tooltip title="Employee is in Not Moving State (Underground)">
                {eventIconsLegacy.commtracNode.minerNotMoving}
              </Tooltip>
            );
          } else if (value === 1) {
            return (
              <Tooltip title="Employee is in Not Moving State (Surface)">
                {eventIconsLegacy.commtracNode.minerNotMoving}
              </Tooltip>
            );
          } else if (value === 2) {
            return (
              <Tooltip title="Moving State Ok (Surface)">
                {eventIconsLegacy.commtracNode.minerOkMoving}
              </Tooltip>
            );
          } else if (value === 3) {
            return (
              <Tooltip title="Moving State Ok (Underground)">
                {eventIconsLegacy.commtracNode.minerOkMoving}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'new_messages',
      hidden: isAssetsOnly,
      renderHeader: () => (
        <Tooltip title="New Messages">
          <EmailIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      doNotExport: true,
      width: 40,
      valueGetter: ({row}) => !!row.e_204,
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_204) {
            return (
              <Tooltip title="New Message">
                <EmailIcon color="success" />
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'msg_conf',
      hidden: isAssetsOnly,
      headerName: 'Msg Conf',
      renderHeader: () => (
        <Tooltip title="Msg Conf">
          <BuildIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      width: 40,
      valueGetter: ({row}) => !!row.e_222,
      renderCell: ({row}) => {
        if (row.type === 'miner') {
          if (row.e_222) {
            return (
              <Tooltip title="Configuration Message Failed">
                {eventIconsLegacy.commtracNode.minerMsgConfFailed}
              </Tooltip>
            );
          } else if (row.configuration_message?.answer === 'NACK') {
            return (
              <Tooltip title="NACK">
                {eventIconsLegacy.commtracNode.minerNack}
              </Tooltip>
            );
          } else if (row.e_232) {
            return (
              <Tooltip title="ACK">
                {eventIconsLegacy.commtracNode.minerAck}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'emergency_response',
      hidden: isAssetsOnly,
      headerName: 'Emergency Response',
      renderHeader: () => (
        <Tooltip title="Emergency Response">
          <AdjustIcon color="error" />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      doNotExport: true,
      width: 40,
      renderCell: ({row}) => {
        if (row.type === 'miner' && selectedBroadcastMessage) {
          const message = row.broadcast_message?.find(
            (i) => i.message_id === selectedBroadcastMessage?.message_id
          );
          if (message?.answer === 'NACK') {
            return (
              <Tooltip title="Not responded">
                {eventIconsLegacy.commtracNode.minerAnswerNack}
              </Tooltip>
            );
          } else if (message?.answer === 'ANS') {
            if (message.broadcast_status === '1') {
              return (
                <Tooltip title={EmployeeEmergencyResponse.OK}>
                  {eventIconsLegacy.commtracNode.employeeEmergencyResponseOk}
                </Tooltip>
              );
            } else if (message.broadcast_status === '2') {
              return (
                <Tooltip title={EmployeeEmergencyResponse.INJURED_BUT_OK}>
                  {
                    eventIconsLegacy.commtracNode
                      .employeeEmergencyResponseInjuredButOk
                  }
                </Tooltip>
              );
            } else if (message.broadcast_status === '3') {
              return (
                <Tooltip
                  title={EmployeeEmergencyResponse.INJURED_AND_NEEDS_HELP}
                >
                  {
                    eventIconsLegacy.commtracNode
                      .employeeEmergencyResponseInjuredAndNeedsHelp
                  }
                </Tooltip>
              );
            }
            return (
              <Tooltip title="ACK">
                {eventIconsLegacy.commtracNode.minerAnswerAck}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.type === 'miner') {
          return row.h_nickname;
        } else if (row.type === 'asset') {
          return row.name;
        }
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}) => capitalize(row.type),
    },
    {
      field: 'type_2',
      headerName: 'Type 2',
      sortable: true,
      valueGetter: ({row}) => getCommtracNodeType(row),
    },
    {
      hidden: isAssetsOnly,
      field: 'h_personal_id',
      headerName: 'Employee ID',
      sortable: true,
      valueGetter: ({row}) => row.h_personal_id || null,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.wifi_enabled) {
          return row.mac_address;
        } else if (row.type === 'miner' && row.commtrac_external_id) {
          return minerAddressMask
            ? row.commtrac_external_id & minerAddressMask
            : null;
        } else if (row.type === 'asset') {
          return row.commtrac_external_id;
        }
      },
    },
    {
      field: 'external_id',
      headerName: 'Proximity ID',
      sortable: true,
    },
    {
      hidden: isAssetsOnly,
      field: 'h_first_name',
      headerName: 'First Name',
      sortable: true,
      valueGetter: ({row}) => row.h_first_name,
    },
    {
      hidden: isAssetsOnly,
      field: 'h_last_name',
      headerName: 'Last Name',
      sortable: true,
      valueGetter: ({row}) => row.h_last_name,
    },
    {
      hidden: isAssetsOnly,
      field: 'h_type_id',
      headerName: 'Job Type',
      sortable: true,
      valueGetter: ({row}) =>
        assetHumanTypes.find((i) => row.h_type_id === i.id)?.name,
    },
    {
      hidden: isEmployeesOnly,
      field: 'm_type_id',
      headerName: 'Machine Type',
      sortable: true,
      valueGetter: ({row}) =>
        machineTypes.find((i) => row.m_type_id === i.id)?.name,
    },
    {
      field: 'commtrac_current_zone_id',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) =>
        zones.find((i) => i.id === row.commtrac_current_zone_id)?.name,
    },
    {
      field: 'commtrac_strongest_cn_name',
      headerName: 'Strongest Node',
      sortable: true,
    },
    {
      field: 'commtrac_date',
      headerName: 'Timestamp',
      sortable: true,
    },
    {
      field: 'commtrac_on_surface',
      headerName: 'Pos',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_on_surface === '1',
      renderCell: ({row}) => {
        if (row.commtrac_on_surface === '1') {
          return (
            <Tooltip title="Surface">
              <BarChartIcon color="success" />
            </Tooltip>
          );
        } else if (row.commtrac_on_surface === '0') {
          return (
            <Tooltip title="Underground">
              <Crop54Icon color="success" />
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'commtrac_pos_lon',
      headerName: 'Longitude',
      sortable: true,
    },
    {
      field: 'commtrac_pos_lat',
      headerName: 'Latitude',
      sortable: true,
    },
    {
      field: 'commtrac_report_frequency',
      headerName: 'Report Frequency',
      sortable: true,
    },
    {
      field: 'a_type',
      hidden: isAssetsOnly,
      headerName: 'Sensor 1',
      sortable: true,
    },
    {
      field: 'a_value',
      hidden: isAssetsOnly,
      headerName: 'Value 1',
      sortable: true,
    },
    {
      field: 'a_type2',
      hidden: isAssetsOnly,
      headerName: 'Sensor 2',
      sortable: true,
    },
    {
      field: 'a_value2',
      hidden: isAssetsOnly,
      headerName: 'Value 2',
      sortable: true,
    },
    {
      field: 'a_type3',
      hidden: isAssetsOnly,
      headerName: 'Sensor 3',
      sortable: true,
    },
    {
      field: 'a_value3',
      hidden: isAssetsOnly,
      headerName: 'Value 3',
      sortable: true,
    },
    {
      field: 'a_type4',
      hidden: isAssetsOnly,
      headerName: 'Sensor 4',
      sortable: true,
    },
    {
      field: 'a_value4',
      hidden: isAssetsOnly,
      headerName: 'Value 4',
      sortable: true,
    },
    {
      field: 'h_birth_date',
      hidden: isAssetsOnly,
      headerName: 'Birthday',
      sortable: true,
      valueGetter: ({row}) =>
        row.h_birth_date
          ? dayjs.utc(row.h_birth_date).format('YYYY-MM-DD')
          : '',
    },
    {
      field: 'h_shift_id',
      hidden: isAssetsOnly,
      headerName: 'Shift',
      sortable: true,
      valueGetter: ({row}) =>
        shifts?.find((shift) => shift.id === row.h_shift_id)?.name,
    },
    {
      field: 'commtrac_beacon_data',
      headerName: 'Beacon Data',
      sortable: true,
    },
    {
      field: 'date_activated',
      headerName: 'Activated at',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.type === 'miner') {
          return row.h_date_activated;
        } else if (row.type === 'asset') {
          return row.m_date_activated;
        }
      },
    },
    {
      field: 'date_deactivated',
      headerName: 'Deactivated at',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.type === 'miner') {
          return row.h_date_deactivated;
        } else if (row.type === 'asset') {
          return row.m_date_deactivated;
        }
      },
    },
  ];

  const shownFields = useMemo(() => {
    if (isEmployeesOnly) {
      return config.shownFields.miners;
    } else if (isAssetsOnly) {
      return config.shownFields.assets;
    }
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter((col) => !col.doNotExport)
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: shownFields?.indexOf(col.field) === -1,
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    if (isEmployeesOnly) {
      onChange?.(
        update(config, {
          shownFields: {
            miners: {$set: fields},
          },
        })
      );
    } else if (isAssetsOnly) {
      onChange?.(
        update(config, {
          shownFields: {
            assets: {$set: fields},
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          shownFields: {
            all: {$set: fields},
          },
        })
      );
    }
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => config.selectedIds?.includes(i.id)),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((i) => i.id) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((i) => i.id),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  /********************/
  /* refresh interval */
  /********************/
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'commtrac_miner_autorefresh_rate'
  );

  return (
    <Box height="100%" width="100%">
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          p={2}
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Box
            minWidth={800}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={40}
            gap={2}
          >
            <Box flexGrow={1} display="flex" height="100%" columnGap={2}>
              <TypeSelect
                value={
                  config.params.node_type?.length !== 1
                    ? 0
                    : config.params.node_type?.includes('asset')
                      ? 1
                      : 2
                }
                size="small"
                fullWidth
                onChange={(v) => {
                  onChange?.(
                    update(config, {
                      params: {
                        node_type: {
                          $set:
                            v === 0
                              ? ['asset', 'miner']
                              : v === 1
                                ? ['asset']
                                : ['miner'],
                        },
                      },
                    })
                  );
                }}
              />
              <StatusSelect
                value={config.params.asset_status}
                size="small"
                fullWidth
                type={config.params.node_type}
                cnt={rows?.length ?? 0}
                onChange={(v) => {
                  onChange?.(
                    update(config, {
                      params: {
                        asset_status: {
                          $set: v,
                        },
                        miner_status: {
                          $set: v,
                        },
                      },
                    })
                  );
                }}
                onUnassignedEmployeesOpen={onUnassignedEmployeesOpen}
                onUnassignedAssetsOpen={onUnassignedAssetsOpen}
              />

              <ZoneSelect
                value={config.params.section_ids?.[0]}
                size="small"
                nullLabel="All Sections"
                fullWidth
                onChange={(v) => {
                  const sectionIds = v ? [+v] : [];
                  onChange?.(
                    update(config, {
                      params: {
                        section_ids: {
                          $set: sectionIds,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <Box display="flex" height="100%">
              <ButtonGroup>
                {/* <AccessControl permissions={['post::/asset-human']}>
                  <AssetHumanItemUpsertButton
                    component={Button}
                    componentProps={{
                      color: 'primary',
                      size: 'small',
                      startIcon: <AddIcon />,
                    }}
                    onSubmitted={() => {
                      onRefresh?.();
                      onChangeLocationCoordinates?.(undefined);
                    }}
                    onClose={() => {
                      onChangeLocationCoordinates?.(undefined);
                    }}
                  >
                    <PersonIcon />
                  </AssetHumanItemUpsertButton>
                </AccessControl>

                <AssetMachineItemUpsertButton
                  component={Button}
                  componentProps={{
                    color: 'primary',
                    startIcon: <AddIcon />,
                  }}
                  onSubmitted={() => onRefresh?.()}
                >
                  <LocalShippingIcon />
                </AssetMachineItemUpsertButton> */}

                <CommtracNodeImportExportButton
                  value={value}
                  component={Button}
                  componentProps={{color: 'primary'}}
                  onSubmitted={() => onRefresh?.()}
                >
                  <ImportExportIcon />
                </CommtracNodeImportExportButton>

                <Button onClick={() => onRefresh?.()}>
                  <RefreshIcon />
                </Button>

                {refreshPeriod ? (
                  <AutoRefreshSettingsSelect refreshPeriod={refreshPeriod} />
                ) : null}

                <Button onClick={() => dataGridRef.current?.printTable()}>
                  <PrintIcon />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          size="small"
          pagination
          page={config.grid.page}
          pageSize={config.grid.pageSize}
          loading={loading}
          shownFields={shownFields}
          sxFooter={{
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
          }}
          footerStart={
            selectedItems.length ? (
              <Box display="flex" alignItems="center" gap={3} px={1}>
                <Box
                  display="flex"
                  gap={0.5}
                  alignItems="center"
                  height="100%"
                  whiteSpace="nowrap"
                >
                  {selectedItems.length} selected
                </Box>

                <AccessControl permissions={['post::/purge']}>
                  <Box display="flex" height={40}>
                    <CommtracNodeItemsPurgeButton
                      ids={selectedRows.map((i) => ({
                        id: i.id,
                        checked_in: i.checked_in,
                        commtrac_ack: i.commtrac_ack,
                        type: i.type,
                        status: i.status,
                        wifi_enabled: i.wifi_enabled,
                      }))}
                      componentProps={{size: 'small', variant: 'outlined'}}
                      onDone={onRefresh}
                    >
                      <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1}} />{' '}
                      Purge
                    </CommtracNodeItemsPurgeButton>
                  </Box>
                </AccessControl>
                <Box
                  display="flex"
                  alignItems="center"
                  height={70}
                  minWidth={490}
                  gap={2}
                  py="15px"
                >
                  <AccessControl permissions={['get::/group-message']}>
                    <Button
                      variant="outlined"
                      sx={{
                        height: '100%',
                      }}
                      startIcon={<ChatIcon />}
                      onClick={() => onChangeIsShownGroupChat?.(true)}
                    >
                      Group Message
                    </Button>
                  </AccessControl>
                  <AccessControl
                    permissions={[
                      'patch::/commtrac-node/miner/broadcast_report_frequency',
                    ]}
                  >
                    <CommtracNodeBroadcastReportFrequencyButton
                      componentProps={{
                        variant: 'outlined',
                        size: 'small',
                        sx: {
                          height: '100%',
                        },
                      }}
                    >
                      Broadcast Reporting Frequency
                    </CommtracNodeBroadcastReportFrequencyButton>
                  </AccessControl>
                </Box>
              </Box>
            ) : null
          }
          onShownFieldsChange={handleChangeShownFields}
          onPageChange={(v) => {
            onChange?.(
              update(config, {
                grid: {
                  page: {
                    $set: v,
                  },
                },
              })
            );
          }}
          onPageSizeChange={(v) => {
            onChange?.(
              update(config, {
                grid: {
                  pageSize: {
                    $set: v,
                  },
                },
              })
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default EmployeeAssetsGridReport;
