import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  amsIds?: number[];
  buttonTitle?: string;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const AMSItemsPurgeNodeButton = <T extends ComponentType = typeof Button>({
  amsIds,
  buttonTitle,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  const submit = async () => {
    setIsSubmitting(true);

    try {
      await API.post(`${apiBaseUrl}/purge`, {
        ams_sensor_ids: amsIds?.length ? amsIds : undefined,
        action: 'device',
      });
      const message = 'Devices purged successfully';
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onDone?.();
      closeModal();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Purge Devices</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to purge the selected device and all of its
            data?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsOpened(false)}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            autoFocus
            onClick={() => submit()}
            color="error"
          >
            {buttonTitle ?? `Purge Devices`}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AMSItemsPurgeNodeButton;
