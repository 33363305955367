import {Autocomplete, TextField, TextFieldProps} from '@mui/material';
import isNil from 'lodash/isNil';
import {useMemo} from 'react';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: number | null;
  nullLabel?: string;
  onChange?: (value?: number | null) => void;
};

export const ShaftClearanceMinersReportTypeSelect = ({
  value,
  disabled,
  fullWidth,
  size,
  nullLabel,
  onChange,
  ...props
}: Props) => {
  const types = [
    {id: 0, name: 'Daily Out'},
    {id: 1, name: 'Daily Not Out'},
    {id: 2, name: 'Weekly'},
    {id: 3, name: 'Monthly'},
    {id: 4, name: 'Quarterly'},
    {id: 5, name: 'Yearly'},
  ];
  const nullValue = -1;

  const options = useMemo(() => {
    if (nullLabel) {
      return [
        {
          id: nullValue,
          name: nullLabel,
        },
        ...types,
      ];
    }
    return types;
  }, [nullLabel]);

  const selectedOptionId = isNil(value) ? nullValue : value;
  const selectedOption = options.find((i) => i.id === selectedOptionId) ?? null;

  return disabled ? (
    <TextField
      {...props}
      value={options.find((i) => i.id === value)?.name ?? ''}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    />
  ) : (
    <Autocomplete
      value={selectedOption ?? undefined}
      disabled={disabled}
      fullWidth={fullWidth}
      options={options}
      size={size}
      disableClearable={true}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={(o) => o.name ?? ''}
      renderInput={(params) => (
        <TextField label="Report" {...props} {...params} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(_, option) => {
        console.log(option);
        const v = !option || option.id === nullValue ? null : option.id;
        console.log(v);
        onChange?.(v);
      }}
    />
  );
};
