import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  ShaftClearanceMinersReportItem,
  ShaftClearanceMinersReportMiner,
  ShaftClearanceMinersReportQuery,
  ShaftClearanceMinersReportResponse,
} from '../../../interfaces/ShaftClearance';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
// import {DateSelect} from '../../selectors/DateSelect';
import {ShaftClearanceMinersReportTypeSelect} from '../../selectors/ShaftClearanceMinersReportTypeSelect';
import {ShiftSelect} from '../../selectors/ShiftSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import ShaftClearanceMinersExportExcel from './ShaftClearanceMinersExportExcel';

const DEFAULT_SHOWN_FIELDS = ['date', 'shift', 'number_not_out', 'number_out'];
const DEFAULT_MINER_SHOWN_FIELDS = [
  'first_name',
  'last_name',
  'name',
  'network_id',
  'type',
  'timestamp',
  'date',
  'shift',
  'section',
  'position',
  'latitude',
  'longitude',
  'strongest_node',
  'job_type',
];
const reportTypeMap = [
  'out',
  'not-out',
  'weekly',
  'monthly',
  'quarterly',
  'yearly',
];

export interface ShaftClearanceMinersReportData {
  refreshInterval?: number | null;
  params?: {
    page?: number;
    limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
    //date_end?: string;
    report_type?: string;
    shift_id?: number | null;
  };
}

export const getShaftClearanceMinersReportData =
  (): ShaftClearanceMinersReportData => ({
    params: {
      report_type: 'out',
    },
  });

export const ShaftClearanceMinersReport = () => {
  const [config, setConfig] = useState(getShaftClearanceMinersReportData);
  const [reportType, setReportType] = useState(0);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<ShaftClearanceMinersReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<ShaftClearanceMinersReportQuery>(
    () =>
      ({
        //date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
        report_type: config.params?.report_type ?? reportTypeMap[reportType],
        shift_id: config.params?.shift_id ?? undefined,
      }) as ShaftClearanceMinersReportQuery,
    [config]
  );

  const fetchData = useCallback(
    async (params: ShaftClearanceMinersReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/shaft-clearance/miners`;
        const resp = await API.get<ShaftClearanceMinersReportResponse>(
          endpoint,
          {
            params,
          }
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const [minerShownFields, setMinerShownFields] = useState(
    DEFAULT_MINER_SHOWN_FIELDS
  );

  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );

  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const minerRows = fetchedData?.miners ?? [];
  const columns: DataGridColumn<ShaftClearanceMinersReportItem>[] = [
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      valueGetter: ({row}) =>
        row.date ? dayjs.utc(row.date).format('YYYY-MM-DD') : '-',
    },
    {
      field: 'shift',
      headerName: 'Shift',
      sortable: true,
    },
    {
      field: 'number_not_out',
      headerName: 'Employees Not Out',
      sortable: true,
    },
    {
      field: 'number_out',
      headerName: 'Employees Out',
      sortable: true,
    },
  ];
  const minerColumns: DataGridColumn<ShaftClearanceMinersReportMiner>[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      sortable: true,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      sortable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'network_id',
      headerName: 'Network ID',
      valueGetter: ({row}) => {
        return minerAddressMask && row.network_id // eslint-disable-next-line
          ? // eslint-disable-next-line no-bitwise
            row?.network_id & minerAddressMask
          : row?.network_id;
      },
      sortable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Last Reported Timestamp',
      sortable: true,
      valueGetter: ({row}) =>
        row.timestamp
          ? dayjs.utc(row.timestamp).format('YYYY-MM-DD HH:mm:ss')
          : '-',
    },
    {
      field: 'date',
      headerName: 'Time of Data Collection',
      sortable: true,
      valueGetter: ({row}) =>
        row.date ? dayjs.utc(row.date).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
    {
      field: 'shift',
      headerName: 'Shift',
      sortable: true,
    },
    {
      field: 'section',
      headerName: 'Section',
      sortable: true,
    },
    {
      field: 'position',
      headerName: 'Position',
      sortable: true,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      sortable: true,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      sortable: true,
    },
    {
      field: 'strongest_node',
      headerName: 'Strongest Node',
      sortable: true,
    },
    {field: 'job_type', headerName: 'Job Type', sortable: true},
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={1}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        Shaft Clearance Employees Report
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            {/* <Box>
              <DateSelect
                value={dayjs(params?.date_end).toDate()}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...params,
                          date_end: v
                            ? dayjs(v).format('YYYY-MM-DD')
                            : undefined,
                          page: 0,
                        },
                      },
                    })
                  );
                }}
              />
            </Box> */}
            <Box minWidth={200}>
              <ShaftClearanceMinersReportTypeSelect
                value={reportType}
                size="small"
                onChange={(v) => {
                  const rt = v || 0;
                  // Set shift_id to null if not supported by report_type
                  const s = [0, 1].includes(Number(v))
                    ? config.params?.shift_id
                    : null;
                  setReportType(rt);
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          report_type: reportTypeMap[rt],
                          shift_id: s,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
            <Box minWidth={200}>
              {['out', 'not-out'].includes(config.params?.report_type || '') ? (
                <ShiftSelect
                  value={config.params?.shift_id}
                  disabled={
                    //@ts-ignore
                    !['out', 'not-out'].includes(config.params?.report_type)
                  }
                  size="small"
                  onChange={(v) => {
                    console.log(v);
                    setConfig?.(
                      update(config, {
                        params: {
                          $set: {
                            ...config.params,
                            shift_id: v,
                          },
                        },
                      })
                    );
                  }}
                />
              ) : (
                <></>
              )}
            </Box>
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Button size="small">
                <ShaftClearanceMinersExportExcel
                  //reportDate={params.date_end}
                  reportType={params.report_type}
                  shiftId={params.shift_id}
                ></ShaftClearanceMinersExportExcel>
              </Button>

              <LoadingButton
                size="small"
                loading={fetchedInProgress}
                variant="outlined"
                onClick={() => params && fetchData(params)}
              >
                <RefreshIcon />
              </LoadingButton>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Button
                size="small"
                onClick={() => dataGridRef.current?.printTable()}
              >
                <PrintIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      {/* @ts-ignore */}
      {['out', 'not-out'].includes(params?.report_type) ? (
        <>
          <DataGrid
            ref={dataGridRef}
            rows={minerRows}
            columns={minerColumns}
            loading={fetchedInProgress}
            shownFields={minerShownFields}
            size="small"
            onShownFieldsChange={setMinerShownFields}
          ></DataGrid>
        </>
      ) : (
        <></>
      )}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        // pagination
        // paginationMode="server"
        pageSize={100000}
        size="small"
        // sortBy={
        //   params?.order
        //     ? {
        //         field: params?.order,
        //         dir: params?.dir === 'DESC' ? 'desc' : 'asc',
        //       }
        //     : null
        // }
        // sortingMode="client"
        // rowCount={fetchedData?.total}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        // onPageChange={(v) => {
        //   setConfig?.(
        //     update(config, {
        //       params: {
        //         $set: {
        //           ...config.params,
        //           page: v,
        //         },
        //       },
        //     })
        //   );
        // }}
        // onPageSizeChange={(v) => {
        //   setConfig?.(
        //     update(config, {
        //       params: {
        //         $set: {
        //           ...config.params,
        //           page: 0,
        //           limit: v,
        //         },
        //       },
        //     })
        //   );
        // }}
        // onSort={(v) => {
        //   if (v) {
        //     setConfig?.(
        //       update(config, {
        //         params: {
        //           $set: {
        //             ...config.params,
        //             order: v.field,
        //             dir: v.dir === 'desc' ? 'DESC' : 'ASC',
        //           },
        //         },
        //       })
        //     );
        //   }
        // }}
        onShownFieldsChange={setShownFields}
      ></DataGrid>
    </Box>
  );
};
