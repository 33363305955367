import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import AMSItemsPurgeEventsButton from './AMSItemsPurgeEventsButton';
import AMSItemsPurgeNodeButton from './AMSItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  amsIds?: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const AMSItemsPurgeButton = <T extends ComponentType = typeof Button>({
  amsIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  const count = useMemo(() => {
    return amsIds?.length ?? 0;
  }, [amsIds]);

  const string = useMemo(() => {
    return `${count} Sensor${count > 1 ? 's' : ''}`;
  }, [count]);

  const purgeAllTitle = useMemo(() => {
    return `Device (${string})`;
  }, [count]);

  const purgeTransactionTitle = useMemo(() => {
    return `Transactions (${string})`;
  }, [count]);

  const purgeAllButtonTitle = useMemo(() => {
    return `Purge Device (${string})`;
  }, [count]);

  const purgeTransactionButtonTitle = useMemo(() => {
    return `Purge Transactions (${string})`;
  }, [count]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <AMSItemsPurgeNodeButton
          amsIds={amsIds}
          buttonTitle={purgeAllButtonTitle}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          {purgeAllTitle}
        </AMSItemsPurgeNodeButton>

        <AMSItemsPurgeEventsButton
          amsIds={amsIds}
          buttonTitle={purgeTransactionButtonTitle}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          {purgeTransactionTitle}
        </AMSItemsPurgeEventsButton>
      </Menu>
    </>
  );
};

export default AMSItemsPurgeButton;
