import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import AccessControl from '../../common/AccessControl';
import {getHasPermission} from '../../common/AccessControl/utils';
import EmployeeItemsPurgeChatEventsButton from '../../employees/buttons/EmployeeItemsPurgeChatEventsButton';
import CommtracNodeItemAssetMinerCheckInOutModal from '../CommtracNodeItemAssetMinerCheckInOutModal';
import CommtracNodeItemsPurgeEventsButton from './CommtracNodeItemsPurgeEventsButton';
import CommtracNodeItemsPurgeNodeButton from './CommtracNodeItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

type commtracNodeObj = {
  id: number;
  checked_in: number | null | undefined;
  commtrac_ack: string | null | undefined;
  type: string | undefined;
  status: 'active' | 'inactive' | null | undefined;
  wifi_enabled: 0 | 1 | null | undefined;
};

type Action = 'checkInOut' | 'checkIn' | 'checkOut';

interface Props<T extends ComponentType> {
  ids: commtracNodeObj[];
  purgeChatEnabled?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const CommtracNodeItemsPurgeButton = <T extends ComponentType = typeof Button>({
  ids,
  purgeChatEnabled = true,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  console.log(ids);
  const me = useAppSelector(({app}) => app.me);
  const Component = component ?? Button;
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;
  const [action, setAction] = useState<Action>();

  const employeeCount = useMemo(() => {
    return ids.filter((el) => el.type === 'miner').length;
  }, [ids]);

  const commtracEnabledEmployees = useMemo(() => {
    return ids.filter((el) => el.type === 'miner' && !el.wifi_enabled);
  }, [ids]);

  const commtracEnabledEmployeesCount = useMemo(() => {
    return commtracEnabledEmployees.length;
  }, [commtracEnabledEmployees]);

  const assetCount = useMemo(() => {
    return ids.filter((el) => el.type === 'asset').length;
  }, [ids]);

  const employeeString = useMemo(() => {
    return `${employeeCount} employee${employeeCount > 1 ? 's' : ''}`;
  }, [employeeCount]);

  const commtracEnabledEmployeeString = useMemo(() => {
    return `${commtracEnabledEmployeesCount} employee${commtracEnabledEmployeesCount > 1 ? 's' : ''}`;
  }, [employeeCount]);

  const assetString = useMemo(() => {
    return `${assetCount} asset${assetCount > 1 ? 's' : ''}`;
  }, [assetCount]);

  const purgeAllTitle = useMemo(() => {
    if (employeeCount > 0 && assetCount > 0) {
      return `Purge Device (${employeeString}, ${assetString})`;
    } else if (employeeCount > 0) {
      return `Purge Device (${employeeString})`;
    } else if (assetCount > 0) {
      return `Purge Device (${assetString})`;
    } else {
      return '';
    }
  }, [employeeCount, assetCount]);

  const purgeTransactionTitle = useMemo(() => {
    if (employeeCount > 0 && assetCount > 0) {
      return `Purge Transactions (${employeeString}, ${assetString})`;
    } else if (employeeCount > 0) {
      return `Purge Transactions (${employeeString})`;
    } else if (assetCount > 0) {
      return `Purge Transactions (${assetString})`;
    } else {
      return '';
    }
  }, [employeeCount, assetCount]);

  const purgeChatTitle = useMemo(() => {
    return `Purge Chat (${commtracEnabledEmployeeString})`;
  }, [commtracEnabledEmployees]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        {getHasPermission(me?.permissions ?? [], ['post::/purge']) && (
          <>
            <CommtracNodeItemsPurgeNodeButton
              ids={ids.map((it: any) => it.id)}
              buttonTitle={purgeAllTitle}
              component={MenuItem}
              onDone={() => {
                setMenuAnchorEl(undefined);
                onDone?.();
              }}
            >
              {purgeAllTitle}
            </CommtracNodeItemsPurgeNodeButton>

            <CommtracNodeItemsPurgeEventsButton
              ids={ids.map((it: any) => it.id)}
              buttonTitle={purgeTransactionTitle}
              component={MenuItem}
              onDone={() => {
                setMenuAnchorEl(undefined);
                onDone?.();
              }}
            >
              {purgeTransactionTitle}
            </CommtracNodeItemsPurgeEventsButton>
          </>
        )}

        {purgeChatEnabled &&
        getHasPermission(me?.permissions ?? [], ['post::/purge']) ? (
          <EmployeeItemsPurgeChatEventsButton
            commtrac_node_ids={commtracEnabledEmployees.map((it: any) => it.id)}
            buttonTitle={purgeChatTitle}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onDone?.();
            }}
          >
            {purgeChatTitle}
          </EmployeeItemsPurgeChatEventsButton>
        ) : null}
        {ids.every(
          (it) =>
            it.status === 'active' && !it.checked_in && it.commtrac_ack !== '0'
        ) && (
          <AccessControl permissions={['patch::/commtrac-node/force_check_in']}>
            <MenuItem
              onClick={() => {
                setAction('checkInOut');
                setMenuAnchorEl(undefined);
              }}
            >
              Force Check In
            </MenuItem>
          </AccessControl>
        )}
        {ids.every(
          (it) =>
            it.status === 'active' && it.checked_in && it.commtrac_ack !== '0'
        ) && (
          <AccessControl
            permissions={['patch::/commtrac-node/force_check_out']}
          >
            <MenuItem
              onClick={() => {
                setAction('checkInOut');
                setMenuAnchorEl(undefined);
              }}
            >
              Force Check Out
            </MenuItem>
          </AccessControl>
        )}
      </Menu>

      <CommtracNodeItemAssetMinerCheckInOutModal
        ids={ids}
        open={!!action}
        onClose={() => setAction(undefined)}
        onSubmitted={onDone}
      />
    </>
  );
};

export default CommtracNodeItemsPurgeButton;
