import {Button, ButtonGroup} from '@mui/material';
import {useState} from 'react';

import {DashboardPanelActionsSlot} from '../DashboardPanelActionsSlot';
import {ShaftClearanceAssetsReport} from './ShaftClearanceAssetsReport';
import {ShaftClearanceMinersReport} from './ShaftClearanceMinersReport';

export const ShaftClearanceReport = () => {
  enum rType {
    Assets,
    Employees,
  }

  const [reportType, setReportType] = useState<rType>(rType.Employees);

  return (
    <>
      <DashboardPanelActionsSlot>
        <ButtonGroup sx={{minWidth: 180}}>
          <Button
            size="small"
            variant={reportType === rType.Employees ? 'contained' : 'outlined'}
            onClick={() => setReportType(rType.Employees)}
          >
            Employees
          </Button>
          <Button
            size="small"
            variant={reportType === rType.Assets ? 'contained' : 'outlined'}
            onClick={() => setReportType(rType.Assets)}
          >
            Assets
          </Button>
        </ButtonGroup>
      </DashboardPanelActionsSlot>

      {reportType === rType.Assets ? (
        <>
          <ShaftClearanceAssetsReport></ShaftClearanceAssetsReport>
        </>
      ) : reportType === rType.Employees ? (
        <>
          <ShaftClearanceMinersReport></ShaftClearanceMinersReport>
        </>
      ) : (
        <>Report Type Error</>
      )}
    </>
  );
};
