import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {ComponentProps, JSXElementConstructor, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {BlastMode} from '../../interfaces/GasMonitoringNode';
import reduxActions from '../../redux/actions';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import Countdown from '../common/Countdown';
import SnackbarMessages from '../common/SnackbarMessages';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  onOpen?: () => void;
}

const AMSBlastButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  onOpen,
}: Props<T>) => {
  const reduxDispatch = useAppDispatch();
  const blastMode = useAppSelector(({app}) => app.blastMode);

  const timer = useMemo(() => {
    return blastMode?.timeout;
  }, [blastMode]);

  const isActive = useMemo(() => {
    return blastMode?.status;
  }, [blastMode]);

  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedDeactivateInProgress, setSubmittedDeactivateInProgress] =
    useState(false);
  const [submittedActivateInProgress, setSubmittedActivateInProgress] =
    useState(false);
  const [submittedExtendInProgress, setSubmittedExtendInProgress] =
    useState(false);

  const submitActivateBlast = async () => {
    setSubmittedActivateInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/blast_mode`;
      const response = await API.patch(endpoint, {
        enable: true,
      });
      const message = 'AMS Blast successfully activated';

      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      setIsOpened(false);
      reduxActions.app.setBlast(reduxDispatch, response.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } finally {
      setSubmittedActivateInProgress(false);
    }
  };

  const submitDeactivateBlast = async () => {
    setSubmittedDeactivateInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/blast_mode`;
      const response = await API.patch<BlastMode>(endpoint, {
        enable: false,
      });
      const message = 'AMS Blast successfully deactivated';
      reduxActions.app.setBlast(reduxDispatch, response.data);

      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      setIsOpened(false);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } finally {
      setSubmittedDeactivateInProgress(false);
    }
  };

  const submitExtendBlast = async () => {
    setSubmittedExtendInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/blast_mode`;
      const response = await API.patch(endpoint, {
        extend: true,
      });
      const message = 'AMS Blast successfully extended';

      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      reduxActions.app.setBlast(reduxDispatch, response.data);
      setIsOpened(false);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } finally {
      setSubmittedExtendInProgress(false);
    }
  };

  return (
    <>
      <Tooltip title="Blast Mode">
        <Component
          variant="outlined"
          {...componentProps}
          startIcon={isActive ? componentProps?.startIcon : null}
          onClick={() => {
            setIsOpened(true);
            onOpen?.();
          }}
          sx={{
            padding: '8px',
          }}
        >
          {isActive ? (
            <>
              Blast Mode Active (<Countdown value={timer ?? 0} />)
            </>
          ) : (
            'Activate Blast Mode'
          )}
        </Component>
      </Tooltip>

      {isOpened ? (
        <Dialog onClose={() => setIsOpened(false)} open={isOpened}>
          <DialogTitle>Blast Mode</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!isActive
                ? 'Are you sure you want to activate blast mode?'
                : 'Would you like to extend or deactivate blast mode?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpened(false)}>Cancel</Button>
            {!isActive && (
              <LoadingButton
                variant="contained"
                loading={submittedActivateInProgress}
                autoFocus
                onClick={() => submitActivateBlast()}
                color="primary"
              >
                Activate
              </LoadingButton>
            )}
            {isActive && (
              <LoadingButton
                variant="contained"
                loading={submittedDeactivateInProgress}
                autoFocus
                onClick={() => submitDeactivateBlast()}
                color="primary"
              >
                Deactivate
              </LoadingButton>
            )}
            {isActive && (
              <LoadingButton
                variant="contained"
                loading={submittedExtendInProgress}
                autoFocus
                onClick={() => submitExtendBlast()}
                color="primary"
              >
                Extended
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default AMSBlastButton;
